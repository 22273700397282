import React, { useState, useEffect } from "react"
import { isEmpty } from "lodash"
import { navigate, graphql } from "gatsby"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"

import { Register } from "page_components/login-register"

import { isUserLoggedIn } from "utils/functions"

const RegisterPage = ({ data, location }) => {
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    const auth = isUserLoggedIn()

    if (!isEmpty(auth)) {
      setLoggedIn(true)
    }

    auth && navigate("/my-account/")
  }, [loggedIn])

  return (
    <Layout
      location={location}
      mainBackground={
        data?.allWpPage?.nodes[0]?.featuredImage?.node?.localFile?.publicURL
      }
    >
      <Breadcrumbs title="Register" />
      <Register setLoggedIn={setLoggedIn} />
    </Layout>
  )
}

export const Head = () => <Seo title="Register" />

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDoxMzIyNg==" } }) {
      nodes {
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default RegisterPage
